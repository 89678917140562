import "./Header.css";

const Header = () => {
    return (
        <header className="site-header">
            <div className="inside-header">
                <div className="site-logo">
                    <a href="https://practicaprende.com/" title="Practica y Aprende" rel="home">
                        <img className="header-image is-logo-image" alt="Practica y Aprende" src="https://practicaprende.com/wp-content/uploads/2020/10/cropped-practicaprende-logo-header.png" title="Practica y Aprende" width="423" height="159" />
                    </a>
                </div>
            </div>
        </header>
    )
}
export default Header;