import "./Home.css";

import Testimonios from "../Components/Testimonios/Testimonios";
import Header from "../Components/Header/Header";
import { useEffect, useState } from "react"
import Swal from 'sweetalert2';
import { get, insert } from "../services/solicitudService";
import TextField from '@mui/material/TextField';
import { Alert, Backdrop, Button, CircularProgress, Divider, Grid, Input, Typography } from "@mui/material";
import CAlert from "../Components/CAlert";
import withReactContent from 'sweetalert2-react-content'

// const MySwal = withReactContent(Swal)

const iniciarErrores = {
    correo: {
        dirty: false,
        hasError: false,
    },
    nombre: {
        dirty: false,
        hasError: false,
    },
    descripcion: {
        dirty: false,
        hasError: false,
    },
};

const iniciarDatosForm = {
    correo: "",
    nombre: "",
    descripcion: "",
};

const Home = () => {
    const [solicitudes, setSolicitudes] = useState([]);
    const [openLoading, setOpenLoading] = useState(false);
    const [formData, setFormData] = useState({ ...iniciarDatosForm })
    const [errores, setErrores] = useState({ ...iniciarErrores })

    const [file, setFile] = useState({});

    useEffect(() => {
        function getSolicitudes() {
            get().then(res => {
                setSolicitudes(res.data);
            });
        }

        // getSolicitudes();
    }, [])

    const mensajeExitoso = () => {
        Swal.fire({
            icon: 'success',
            title: 'Solicitud registrada!',
            text: 'Por favor estar pendiente del correo donde se enviará el análisis de la solución',
            confirmButtonText: "Cerrar"
        })
    }

    const mensajeFallido = () => {
        Swal.fire({
            icon: 'error',
            title: 'No se ha podido registrar la solicitud!',
            text: 'Por favor comunicate al correo contacto@practicaprende.com y escribes la solicitud que quieres realizar!',
            confirmButtonText: "Cerrar"
        })
    }

    const limpiarCampos = () => {
        setFormData({
            correo: "",
            nombre: "",
            descripcion: "",
        });
        setErrores({
            correo: {
                dirty: false,
                hasError: false,
            },
            nombre: {
                dirty: false,
                hasError: false,
            },
            descripcion: {
                dirty: false,
                hasError: false,
            },
        });
    }

    const handleSubmit = (e) => {
        e.preventDefault();
        setOpenLoading(true);

        let formDataToSend = new FormData();
        if (file.name)
            formDataToSend.append("file", file);
        formDataToSend.append("nombre", formData.nombre);
        formDataToSend.append("descripcion", formData.descripcion);
        formDataToSend.append("correo", formData.correo);
        insert(formDataToSend)
            .then(response => {
                setOpenLoading(false);
                mensajeExitoso();
                limpiarCampos();
            })
            .catch(err => {
                setOpenLoading(false);
                mensajeFallido();
                console.error(`[[Error From API]]: ${err?.response?.data?.messages?.error}`)
            })
    }
    const onChange = (e) => {
        const file = e.target.files[0];
        setFile(file);
    }

    const validateCorreo = ({ target }) => {
        setFormData({ ...formData, correo: target.value })
        const err = { ...errores };
        err.correo.dirty = true
        const validateEmail = new RegExp(/^[\w-\.]+@([\w-]+\.)+[\w-]{2,4}$/);
        const isValid = validateEmail.test(target.value);
        err.correo.hasError = !isValid;
        setErrores(err);

    }
    const onChangeField = (e, field) => {
        setFormData({ ...formData, [field]: e.target.value })
        const err = { ...errores };
        err[field].dirty = true
        err[field].hasError = e.target.value === ""
        setErrores(err);
    }

    const anyHasDirty = !errores.correo.dirty || !errores.nombre.dirty || !errores.descripcion.dirty
    const anyHasError = errores.correo.hasError || errores.nombre.hasError || errores.descripcion.hasError

    const checkDisabledButton = openLoading || anyHasDirty || anyHasError;

    const solicitudesAnteriores = [
        {
            id: 1,
            title: "Consolidar muchas hojas",
            description: "Tengo un archivo de Excel con 150 hojas, y todas tienen el mismo formato, por lo cual mi solicitud es consolidar todas las hojas en una sola.",
        }
        ,
        {
            id: 2,
            title: "Reconocimiento OCR",
            description: "Luego de escanear 1000 archivos, necesito leer el contenido de cada archivo para encontrar un código y con base en ese código renombrar cada uno de los archivos.",
        },
        // {
        //     id: 3,
        //     title: "Consolidar muchas hojas",
        //     description: "Tengo un archivo de Excel con 150 hojas, y todas tienen el mismo formato, por lo cual mi solicitud es consolidar todas las hojas en una sola.",
        // },
        // {
        //     id: 4,
        //     title: "Consolidar muchas hojas",
        //     description: "Tengo un archivo de Excel con 150 hojas, y todas tienen el mismo formato, por lo cual mi solicitud es consolidar todas las hojas en una sola.",
        // }
    ]

    return (
        <>
            <Header />
            <div className="container">
                <Grid
                    container
                    direction="row"
                    justifyContent="center"
                    alignItems="center" spacing={{ xs: 2, md: 3 }} columns={{ xs: 4, sm: 8, md: 12 }}>
                    <Grid item xs={3} sm={7} md={8} pb={12} mt={2} className="gridContainer">
                        <Grid container
                            direction="row"
                            justifyContent="center"
                            alignItems="center" >
                            <h1>¿Necesitas ayuda con tu Excel?</h1>
                        </Grid>
                        <Typography variant="body1" paragraph>
                            En Practica y Aprende, hemos diseñado esta sección para <strong>ayudar</strong> a nuestra comunidad con sus proyectos personales o de trabajo, emprendimientos, etc.
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Solo necesitas llenar el formulario que encontrarás más adelante y <strong>hacernos tu consulta</strong> sobre el proyecto o actividad que necesitas realizar.
                        </Typography>
                        <br/>
                        <Typography variant="h5" paragraph>
                            ¿Es totalmente Gratis?
                        </Typography>
                        <Typography variant="body1" paragraph>
                            Si, puedes acceder a realizar la solicitud <strong>totalmente gratis</strong>, nosotros la analizamos y a vuelta de correo te entregamos el <strong>costo y el tiempo de entrega</strong> sin comprimiso.
                        </Typography>
                        <br/>
                        <Typography variant="h5" paragraph>
                            Si necesitas que realice un trabajo para ti
                        </Typography>
                        <Typography variant="body1" paragraph>
                            una macro o una automatizacion en Excel, entonces al mismo correo te enviaré el precio por el cual lo realizaria y la fecha de entrega .
                        </Typography>
                        <br/>
                        <Typography variant="body1" paragraph>
                            Puedes ver las <strong>solicitudes</strong> que se han realizado.
                        </Typography>

                        <h2 className="h2solicitudes">SOLICITUDES ANTERIORES</h2>
                        <Grid container alignItems="center" justifyContent="center" spacing={{ xs: 2 }} pt={1} mb={4} direction="row">
                            {solicitudesAnteriores.map(({ id, title, description }) => (
                                <Testimonios key={id} title={title} description={description} />
                            ))}
                        </Grid>
                        <Typography variant="h5" paragraph>
                            Escribenos de una vez y...
                        </Typography>
                        <Typography variant="body1" paragraph>
                            obtén un presupuesto <strong>sin compromiso</strong>; para poder ejecutar ese proyecto que necesitas culminar.
                        </Typography>
                        <Typography variant="h5" paragraph>
                            Si estás de acuerdo
                        </Typography>
                        <Typography variant="body1" paragraph>
                             por favor <strong>cuéntanos</strong> en que te podemos ayudar, si quieres puedes subir un archivo de ejemplo:
                        </Typography>
                        <form onSubmit={handleSubmit}>
                            <Grid container spacing={{ xs: 2, md: 3 }}>
                                <Grid item xs={12} sm={12} mr={2}>
                                    <TextField
                                        id="txt_correo"
                                        type="text"
                                        label="Correo electronico"
                                        variant="outlined"
                                        value={formData.correo}
                                        error={errores.correo.dirty && errores.correo.hasError}
                                        onChange={validateCorreo}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} mr={2}>
                                    <TextField
                                        id="txt_nombre"
                                        type="text"
                                        label="Tu Nombre"
                                        variant="outlined"
                                        error={errores.nombre.dirty && errores.nombre.hasError}
                                        value={formData.nombre}
                                        onChange={(e) => onChangeField(e, "nombre")}
                                    />
                                </Grid>
                                <Grid item xs={9} sm={12} mr={2}>
                                    <TextField
                                        fullWidth
                                        id="txt_descripcion"
                                        label="Descripcion"
                                        error={errores.descripcion.dirty && errores.descripcion.hasError}
                                        multiline
                                        rows={4}
                                        variant="outlined"
                                        value={formData.descripcion}
                                        onChange={(e) => onChangeField(e, "descripcion")}
                                    />
                                </Grid>
                                <Grid item xs={12} sm={12} mr={2}>
                                    <input type="file" onChange={onChange} />
                                </Grid>
                                <Grid item xs={12} sm={12} mr={2}>
                                    <Button type="submit" disabled={checkDisabledButton} color="success" variant="contained">Enviar Solicitud</Button>
                                </Grid>
                            </Grid>
                        </form>
                        <Backdrop
                            sx={{ color: '#fff', zIndex: (theme) => theme.zIndex.drawer + 1 }}
                            open={openLoading}
                        >
                            <CircularProgress color="inherit" />
                        </Backdrop>
                    </Grid>
                </Grid>
            </div>
        </>
    )
}
export default Home;