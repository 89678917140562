import axios from "axios"

export const get = () => {
    return axios.get(`https://solicitud.practicaprende.com/API/public/solicitud`);
}
export const insert = (formData) => {
    return axios({
        method: "POST",
        url: `https://solicitud.practicaprende.com/API/public/solicitud/insertar`,
        data: formData,
        headers: {
            "Content-Type": "application/x-www-form-urlencoded",
        },
    });
}
